import store from "@/state/store";

export default [
    {
        path: "/",
        name: "dashboard",
        meta: {
            title: "Dashboard",
            authRequired: true,
        },
        component: () => import("../views/dashboard/ecommerce/index.vue"),
    },
    {
        path: "/login",
        name: "login",
        component: () => import("../views/account/login.vue"),
        meta: {
            title: "Login",
            beforeResolve(routeTo, routeFrom, next) {
                if (store.getters["auth/loggedIn"]) {
                    next({name: "dashboard"});
                } else {
                    next();
                }
            },
        },
    },
    {
        path: "/recuperar-senha",
        name: "recuperar-senha",
        component: () => import("../views/account/forgot-password.vue"),
        meta: {
            title: "Recuperar Senha",
        },
    },
    {
        path: '/nova-senha',
        name: 'nova-senha',
        meta: {
            title: "Recuperar Senha"
        },
        component: () => import('../views/account/change-password')
    },
    {
        path: "/logout",
        name: "logout",
        component: () => import("../views/account/logout.vue"),
        meta: {
            title: "Logout",
        },
    },
    {
        path: "/usuarios",
        name: "users",
        meta: {
            title: "Usuários",
            authRequired: true,
            authLevel: true
        },
        component: () => import("../views/user/users"),
    },
    {
        path: "/usuarios/meu-perfil",
        name: "profile",
        meta: {
            title: "Meu Perfil",
            authRequired: true
        },
        component: () => import("../views/user/profile"),
    },
    {
        path: "/quadros",
        name: "boards",
        meta: {
            title: "Quadros",
            authRequired: true,
        },
        component: () => import("../views/boards/boards.vue"),
    },
    {
        path: "/status",
        name: "status",
        meta: {
            title: "Status",
            authRequired: true,
            authLevel: true
        },
        component: () => import("../views/status/status.vue"),
    },
    {
        path: "/agenda",
        name: "agenda",
        meta: {
            title: "Agenda Particula",
            authRequired: true
        },
        component: () => import("../views/schedule/particular.vue"),
    },
    {
        path: "/agenda/reunioes",
        name: "agenda-reunioes",
        meta: {
            title: "Agenda de Reuniões",
            authRequired: true
        },
        component: () => import("../views/schedule/meeting.vue"),
    },
    {
        path: "/lixeira",
        name: "trash",
        meta: {
            title: "Lixeira",
            authRequired: true,
            authLevel: true
        },
        component: () => import("../views/trash/trash.vue"),
    },
    {
        path: "/auditoria",
        name: "audit",
        meta: {
            title: "Auditoria",
            authRequired: true,
            authLevel: true
        },
        component: () => import("../views/audit/audit.vue"),
    },
    {
        path: "/leads",
        name: "leads",
        meta: {
            title: "Leads",
            authRequired: true
        },
        component: () => import("../views/leads/leads.vue"),
    },
    {
        path: "/leads/:id",
        name: "activities",
        meta: {
            title: "Históricos",
            authRequired: true
        },
        component: () => import("../views/leads/activities.vue"),
        props: true
    },
    {
        path: "/leads/em-espera",
        name: "on-hold",
        meta: {
            title: "Leads em Espera",
            authRequired: true
        },
        component: () => import("../views/leads/on-hold"),
    },
    {
        path: "/clientes",
        name: "clients",
        meta: {
            title: "Clientes",
            authRequired: true
        },
        component: () => import("../views/client/index"),
    },
];