import {LIST_TRASH} from "@/state/mutations-types";
import {GET_TRASH, REFRESH_TRASH} from "@/state/actions-type";
import {Forbidden, getUrl, notifyError, notifySuccess} from "@/components/composables/functions";
import http from "@/http";
import {setSessionTrash} from "@/components/composables/getSessions";

export const state = {
    trash: {
        total: '',
        partial: '',
        status: '',
        message: {}
    }
};

export const mutations = {
    [LIST_TRASH](state, trash) {
        state.trash = trash;
    },
};
export const actions = {

    [GET_TRASH]({commit}) {
        if (!localStorage.getItem('Trash')) setSessionTrash()
        let obj = JSON.parse(localStorage.getItem('Trash'));
        const url = getUrl(obj.paramns);
        const route = 'lixeira/listar/' + url;
        http.get(route, {
            headers: {'x-auth': ` Bearer ${localStorage.getItem('jwt')} `}
        })
            .then(response => commit(LIST_TRASH, response.data))
            .catch(errors => {
                console.log(errors);
                notifyError('Algo deu errado. Contate o administrador!');
                Forbidden(errors);
            });
    },

    [REFRESH_TRASH](contexto, id) {
        http.get('lixeira/restaurar/' + id, {
            headers: {'x-auth': ` Bearer ${localStorage.getItem('jwt')} `}
        })
            .then(response => {
                notifySuccess(response.data.message)
                setTimeout(function () {
                    document.getElementById('line' + id).style.display = 'none';
                }, 200)
            })
            .catch(errors => {
                console.log(errors);
                notifyError('Algo deu errado. Contate o administrador!');
                Forbidden(errors);
            });
    },
};