// Actions Usuários
export const GET_USERS = 'GET_USERS';
export const REGISTER_USERS = 'REGISTER_USERS';
export const ADMIN_USERS = 'ADMIN_USERS';
export const GET_CONSULTANTS = 'GET_CONSULTANTS';
export const DELETE_USERS = 'DELETE_USERS';

// Actions Quadros
export const GET_BOARDS = 'GET_BOARDS';
export const REGISTER_BOARDS = 'REGISTER_BOARDS';

// Actions Lixeira
export const GET_TRASH = 'GET_TRASH';
export const REFRESH_TRASH = 'REFRESH_TRASH';

// Actions Setor
export const PUT_SECTOR = 'PUT_SECTOR';
export const SET_SECTOR = 'SET_SECTOR';

// Actions Status
export const LIST_STATUS = 'LIST_STATUS';
export const LIST_STATUS_BY_SECTOR = 'LIST_STATUS_BY_SECTOR';
export const REGISTER_STATUS = 'REGISTER_STATUS';

// Actions Leads
export const GET_LEADS = 'GET_LEADS';
export const REGISTER_LEADS = 'REGISTER_LEADS';
export const DELETE_LEADS = 'DELETE_LEADS';
export const UPDATE_STATUS = 'UPDATE_STATUS';
export const SET_RESPONSIBLE = 'SET_RESPONSIBLE';
export const EDIT_LEAD = 'EDIT_LEAD';
export const GET_LOGS = 'GET_LOGS';
export const GET_ACTIVITIES = 'GET_ACTIVITIES';
export const REGISTER_ACTIVITY = 'REGISTER_ACTIVITY';
export const EXPORT_LEADS = 'EXPORT_LEADS';