import {ADD_ACTIVITY, LIST_ACTIVITIES, LIST_LEADS, LIST_LOGS} from "../mutations-types";
import {
    DELETE_LEADS,
    EDIT_LEAD, EXPORT_LEADS, GET_ACTIVITIES,
    GET_LEADS,
    GET_LOGS, REGISTER_ACTIVITY,
    REGISTER_LEADS,
    SET_RESPONSIBLE,
    UPDATE_STATUS
} from "../actions-type";
import {
    endLoading,
    Forbidden,
    getUrl,
    hideToast,
    showNotify,
    notifyError,
    setCssTimeline
} from '@/components/composables/functions';
import http from "@/http";
import {setSessionLeads, setSessionLogs} from "@/components/composables/getSessions";
import Swal from "sweetalert2";

export const state = {
    leads: {
        total: '', partial: '', status: '', message: {}
    },
    logs: {
        total: 1, partial: 1, message: {}
    },
    activities: {
        arr: {total: 0, activities: {}},
        cpf: null,
        bloc: null,
        name: null,
        phone: null,
        responsible: null,
        status: null,
    }
};


export const mutations = {
    [LIST_LEADS](state, leads) {
        state.leads = leads;
        endLoading('form', 'save');
        const close = document.getElementById('closeModalRegister');
        if (close) close.click();
    },

    [LIST_LOGS](state, logs) {
        state.logs = logs;
        endLoading('form', 'save');
    },

    [LIST_ACTIVITIES](state, activities) {
        state.activities = activities;
        endLoading('activitiesBody');
        const length = state.activities.arr.activities.length;
        setCssTimeline(length);
    },

    [ADD_ACTIVITY](state, activity) {
        let arr = [];
        arr.push(activity);
        const activities = state.activities.arr.activities;
        activities.forEach(element => arr.push(element));
        state.activities.arr.activities = arr;
        state.activities.arr.total = arr.length;
        endLoading('formStatus', 'saveStatus');
        const length = state.activities.arr.activities.length;
        setCssTimeline(length);
    }
};
export const actions = {

    [GET_LEADS]({commit}) {
        if (!localStorage.getItem('Leads')) setSessionLeads();
        let obj = JSON.parse(localStorage.getItem('Leads'));
        const url = getUrl(obj.paramns);
        const route = 'leads/listar/' + url;
        http.get(route, {
            headers: {'x-auth': ` Bearer ${localStorage.getItem('jwt')} `}
        })
            .then(response => commit(LIST_LEADS, response.data))
            .catch(errors => {
                console.log(errors);
                notifyError('Algo deu errado. Contate o administrador!');
                Forbidden(errors);
            });
    },

    [REGISTER_LEADS]({commit}, formData) {
        http.post('leads/registrar', formData, {
            headers: {
                'x-auth': `Bearer ${localStorage.getItem('jwt')}`
            }
        })
            .then(response => {
                showNotify(response)
                if (!localStorage.getItem('Leads')) setSessionLeads();
                let obj = JSON.parse(localStorage.getItem('Leads'));
                const url = getUrl(obj.paramns);
                const route = 'leads/listar/' + url;
                http.get(route, {
                    headers: {
                        'x-auth': `Bearer ${localStorage.getItem('jwt')}`
                    }
                }).then(response => commit(LIST_LEADS, response.data));
            })
            .catch(errors => {
                console.log(errors);
                endLoading('form', 'save');
                notifyError('Algo deu errado. Contate o administrador!');
                Forbidden(errors);
            })
    },

    [DELETE_LEADS]({dispatch}, formData) {
        http.post('leads/excluir/', formData, {
            headers: {
                'x-auth': `Bearer ${localStorage.getItem('jwt')}`
            }
        })
            .then(response => {
                dispatch(GET_LEADS);
                Swal.fire("", response.data.message);
                hideToast();
            })
            .catch(errors => {
                console.log(errors);
                endLoading('form', 'save');
                notifyError('Algo deu errado. Contate o administrador!');
                Forbidden(errors);
            })
    },

    async [SET_RESPONSIBLE]({dispatch}, formData) {
        try {
            const result = await http.post('leads/novo-consultor', formData, {
                headers: {
                    'x-auth': `Bearer ${localStorage.getItem('jwt')}`
                }
            })
            showNotify(result)
            dispatch(GET_LEADS);
            hideToast()
            return result.data;
        } catch (errors) {
            console.log(errors);
            endLoading('form', 'save');
            notifyError(errors.response.data.message);
            Forbidden(errors);
        }
    },

    [UPDATE_STATUS](context, formData) {
        http.post('leads/alterar-status', formData, {
            headers: {
                'x-auth': `Bearer ${localStorage.getItem('jwt')}`
            }
        })
            .then(response => {
                showNotify(response)
                endLoading('formStatus', 'saveStatus');
                document.getElementById('closeModalStatus').click();
                const id = 'buttonStatus' + formData.leadId;
                const button = document.getElementById(id);
                button.style.backgroundColor = response.data.color;
                button.style.borderColor = response.data.color;
                button.innerText = response.data.name;
            })
            .catch(errors => {
                console.log(errors);
                endLoading('formStatus', 'saveStatus');
                notifyError(errors.response.data.message);
                Forbidden(errors);
            })
    },

    async [EDIT_LEAD](context, id) {
        try {
            const result = await http.get('leads/editar/' + id, {
                headers: {
                    'x-auth': `Bearer ${localStorage.getItem('jwt')}`
                }
            });
            return result.data.message
        } catch (error) {
            console.error(error);
            notifyError(error.response.data.message);
            Forbidden(error.response);
        }
    },

    [GET_LOGS]({commit}, id) {
        if (!localStorage.getItem('Logs')) setSessionLogs();
        let obj = JSON.parse(localStorage.getItem('Logs'));
        const url = getUrl(obj.paramns);
        const route = `leads/logs/${id}/${url}`;
        http.get(route, {
            headers: {'x-auth': ` Bearer ${localStorage.getItem('jwt')} `}
        })
            .then(response => commit(LIST_LOGS, response.data))
            .catch(errors => {
                console.log(errors);
                notifyError('Algo deu errado. Contate o administrador!');
                Forbidden(errors);
            });
    },

    [GET_ACTIVITIES]({commit}, id) {
        http.get('leads/historicos/' + id, {
            headers: {'x-auth': ` Bearer ${localStorage.getItem('jwt')} `}
        })
            .then(response => commit(LIST_ACTIVITIES, response.data.message))
            .catch(errors => {
                console.log(errors);
                notifyError('Algo deu errado. Contate o administrador!');
                Forbidden(errors);
            });
    },

    [REGISTER_ACTIVITY]({commit}, formData) {
        http.post('leads/nova-atividade', formData, {
            headers: {
                'x-auth': `Bearer ${localStorage.getItem('jwt')}`
            }
        })
            .then(response => {
                showNotify(response)
                endLoading('formStatus', 'saveStatus');
                document.getElementById('closeModalStatus').click();
                commit(ADD_ACTIVITY, response.data.data)
            })
            .catch(errors => {
                console.log(errors);
                endLoading('formStatus', 'saveStatus');
                notifyError(errors.response.data.message);
                Forbidden(errors);
            })
    },

    [EXPORT_LEADS]() {
        if (!localStorage.getItem('Leads')) setSessionLeads();
        let obj = JSON.parse(localStorage.getItem('Leads'));
        const user = JSON.parse(localStorage.getItem('user'));
        obj.paramns.responsible = user._id;
        const url = getUrl(obj.paramns);
        const route = 'http://apicrm.techvision.dev/leads/exportar-csv/' + url;
        window.open(route, '_blank');
    },
};

