<template>
	<router-view>

	</router-view>
    <notifications position="bottom center" style="bottom: 30px"/>
</template>
<script>

export default {
	name: 'App',
}
</script>
