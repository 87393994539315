import {LIST_ADMINS, LIST_CONSULTANTS, LIST_BOARDS} from "../mutations-types";
import {GET_BOARDS, REGISTER_BOARDS} from "../actions-type";
import {endLoading, Forbidden, getUrl, showNotify, notifyError} from '@/components/composables/functions';
import http from "@/http";
import {setSessionBoards} from "@/components/composables/getSessions";

export const state = {
    boards: {
        total: '', partial: '', status: '', message: {}
    },
};


export const mutations = {
    [LIST_BOARDS](state, boards) {
        state.boards = boards;
        endLoading('form', 'save');
        const close = document.getElementById('closeModalRegister');
        close.click();
    },
    [LIST_ADMINS](state, boards) {
        state.admins = boards.message;
    },
    [LIST_CONSULTANTS](state, boards) {
        state.consultants = boards.message;
    },
};
export const actions = {

    [GET_BOARDS]({commit}) {
        if (!localStorage.getItem('Boards')) setSessionBoards();
        let obj = JSON.parse(localStorage.getItem('Boards'));
        const url = getUrl(obj.paramns);
        const route = 'quadros/listar/' + url;
        http.get(route, {
            headers: {'x-auth': ` Bearer ${localStorage.getItem('jwt')} `}
        })
            .then(response => commit(LIST_BOARDS, response.data))
            .catch(errors => {
                console.log(errors);
                notifyError('Algo deu errado. Contate o administrador!');
                Forbidden(errors);
            });
    },

    [REGISTER_BOARDS]({commit}, formData) {
        http.post('quadros/cadastrar', formData, {
            headers: {
                'x-auth': `Bearer ${localStorage.getItem('jwt')}`
            }
        })
            .then(response => {
                showNotify(response)
                if (!localStorage.getItem('Boards')) setSessionBoards();
                let obj = JSON.parse(localStorage.getItem('Boards'));
                const url = getUrl(obj.paramns);
                const route = 'quadros/listar/' + url;
                http.get(route, {
                    headers: {
                        'x-auth': `Bearer ${localStorage.getItem('jwt')}`
                    }
                }).then(response => commit(LIST_BOARDS, response.data));
            })
            .catch(errors => {
                console.log(errors);
                notifyError('Algo deu errado. Contate o administrador!');
                Forbidden(errors);
            })
    },
};