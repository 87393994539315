import store from "@/state/store";

export function setStore(route) {
    store.dispatch('sectorModule/PUT_SECTOR');
    if (route === '/leads') {
        store.dispatch('leadStatusModule/LIST_STATUS_BY_SECTOR')
        store.dispatch('leadsModule/GET_LEADS')
    }
}

export function setSessionUsers() {
    const users = {
        url: 'usuarios',
        module: 'usersModule/',
        paramns: {
            index: 0,
            limit: 25,
            type: '',
            email: '',
            name: '',
            sector: '',
            level: '',
            active: '',
        }
    }
    localStorage.setItem('Users', JSON.stringify(users));
}

export function setSessionTrash() {
    const trash = {
        url: 'lixeira',
        module: 'trashModule/',
        paramns: {
            index: 0,
            limit: 25,
            responsible: '',
            start: '',
            end: '',
            type: '',
        }
    }
    localStorage.setItem('Trash', JSON.stringify(trash));
}

export function setSessionStatus() {
    const status = {
        url: 'status',
        module: 'leadStatusModule/',
        paramns: {
            index: 0,
            limit: 25,
            sector: ''
        }
    }
    localStorage.setItem('Status', JSON.stringify(status));
}

export function setSessionLeads() {
    const leads = {
        url: 'leads',
        module: 'leadsModule/',
        paramns: {
            index: 0,
            name: '',
            status: '',
            responsible: '',
            order: 'l.updated',
            seq: 'desc',
            city: '',
            state: '',
            start: '',
            end: '',
            sector: store.state.sectorModule.sector,
            toView: 1,
            cpf: '',
            bloc: '',
            phone: '',
            limit: 25,
        }
    }
    localStorage.setItem('Leads', JSON.stringify(leads));
}

export function setSessionLogs() {
    const logs = {
        module: 'leadsModule/',
        paramns: {
            index: 0,
            limit: 25,
        }
    }
    localStorage.setItem('Logs', JSON.stringify(logs));
}

export function setSessionBoards() {
    const users = {
        url: 'quadros',
        module: 'boardsModule/',
        paramns: {
            index: 0,
            limit: 25,
            name: '',
            sector: '',
            active: '',
        }
    }
    localStorage.setItem('Boards', JSON.stringify(users));
}