import {LIST_ADMINS, LIST_CONSULTANTS, LIST_USERS} from "../mutations-types";
import {DELETE_USERS, ADMIN_USERS, GET_USERS, REGISTER_USERS, GET_CONSULTANTS} from "../actions-type";
import {endLoading, Forbidden, getUrl, showNotify, notifyError} from '@/components/composables/functions';
import http from "@/http";
import {setSessionUsers} from "@/components/composables/getSessions";
import Swal from "sweetalert2";

export const state = {
    users: {
        total: '', partial: '', status: '', message: {}
    },
    admins: {},
    consultants: {}
};


export const mutations = {
    [LIST_USERS](state, users) {
        state.users = users;
        endLoading('form', 'save');
        const close = document.getElementById('closeModalRegister');
        close.click();
    },
    [LIST_ADMINS](state, users) {
        state.admins = users.message;
    },
    [LIST_CONSULTANTS](state, users) {
        state.consultants = users.message;
    },
};
export const actions = {

    [GET_USERS]({commit}) {
        if (!localStorage.getItem('Users')) setSessionUsers();
        let obj = JSON.parse(localStorage.getItem('Users'));
        const url = getUrl(obj.paramns);
        const route = 'usuarios/listar/' + url;
        http.get(route, {
            headers: {'x-auth': ` Bearer ${localStorage.getItem('jwt')} `}
        })
            .then(response => commit(LIST_USERS, response.data))
            .catch(errors => {
                console.log(errors);
                notifyError('Algo deu errado. Contate o administrador!');
                Forbidden(errors);
            });
    },

    [REGISTER_USERS]({commit}, formData) {
        http.post('usuarios/cadastrar', formData, {
            headers: {
                'x-auth': `Bearer ${localStorage.getItem('jwt')}`
            }
        })
            .then(response => {
                showNotify(response)
                if (!localStorage.getItem('Users')) setSessionUsers();
                let obj = JSON.parse(localStorage.getItem('Users'));
                const url = getUrl(obj.paramns);
                const route = 'usuarios/listar/' + url;
                http.get(route, {
                    headers: {
                        'x-auth': `Bearer ${localStorage.getItem('jwt')}`
                    }
                }).then(response => commit(LIST_USERS, response.data));
            })
            .catch(errors => {
                console.log(errors);
                notifyError('Algo deu errado. Contate o administrador!');
                Forbidden(errors);
            })
    },

    [DELETE_USERS](contexto, id) {
        http.get('usuarios/excluir/' + id, {
            headers: {
                'x-auth': `Bearer ${localStorage.getItem('jwt')}`
            }
        })
            .then(() => {
                Swal.fire("", "Usuário excluído com sucesso!", "success");
                setTimeout(function () {
                    document.getElementById('line' + id).style.display = 'none';
                }, 200)
            })
            .catch(errors => {
                console.log(errors);
                notifyError('Algo deu errado. Contate o administrador!');
                Forbidden(errors);
            })
    },

    [ADMIN_USERS]({commit}) {
        const route = "usuarios/listar/?active=1&level=1&index=0&limit=100&sector=";
        http.get(route, {
            headers: {
                'x-auth': `Bearer ${localStorage.getItem('jwt')}`
            }
        })
            .then(response => commit(LIST_ADMINS, response.data))
            .catch(errors => {
                console.log(errors);
                notifyError('Algo deu errado. Contate o administrador!');
                Forbidden(errors);
            });
    },

    [GET_CONSULTANTS]({commit}) {
        const route = "usuarios/listar/?active=1&level=6&index=0&limit=100&sector=";
        http.get(route, {
            headers: {
                'x-auth': `Bearer ${localStorage.getItem('jwt')}`
            }
        })
            .then(response => commit(LIST_CONSULTANTS, response.data))
            .catch(errors => {
                console.log(errors);
                notifyError('Algo deu errado. Contate o administrador!');
                Forbidden(errors);
            });
    },
};

