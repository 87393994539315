import {GET_SECTOR} from "@/state/mutations-types";
import {PUT_SECTOR, SET_SECTOR} from "@/state/actions-type";

export const state = {
    sector: ''
};

export const mutations = {

    [GET_SECTOR](state, sector) {
        state.sector = sector;
    },

};

export const actions = {

    [PUT_SECTOR]({commit}) {
        if (state.sector == 1) {
            localStorage.setItem('sector', '2');
            commit(GET_SECTOR, 2)
        } else {
            localStorage.setItem('sector', '1');
            commit(GET_SECTOR, 1)
        }
    },
    [SET_SECTOR]({commit}, value) {
        localStorage.setItem('sector', value);
        commit(GET_SECTOR, value)
    },
}