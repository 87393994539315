import {GET_STATUS, GET_STATUS_BY_SECTOR} from "@/state/mutations-types";
import {LIST_STATUS, LIST_STATUS_BY_SECTOR, REGISTER_STATUS} from "@/state/actions-type";
import {endLoading, Forbidden, getUrl, showNotify, notifyError} from "@/components/composables/functions";
import http from "@/http";
import {setSessionStatus} from "@/components/composables/getSessions";


export const state = {
    status: {
        total: '',
        partial: '',
        status: '',
        message: {}
    },
    statusBySector: {
        id: '',
        name: 'Carregando dados'
    }
};

export const mutations = {

    [LIST_STATUS](state, status) {
        state.status = status;
    },

    [GET_STATUS_BY_SECTOR](state, statusBySector) {
        state.statusBySector = statusBySector;
    },

};

export const actions = {

    [GET_STATUS]({commit}) {
        if (!localStorage.getItem('Status')) setSessionStatus();
        let obj = JSON.parse(localStorage.getItem('Status'));
        const url = getUrl(obj.paramns);
        const route = 'status/listar/' + url;
        http.get(route, {
            headers: {'x-auth': ` Bearer ${localStorage.getItem('jwt')} `}
        })
            .then(response => commit(LIST_STATUS, response.data))
            .catch(errors => {
                console.log(errors);
                notifyError('Algo deu errado. Contate o administrador!')
                Forbidden(errors);
            });
    },

    [LIST_STATUS_BY_SECTOR]({commit}) {
        const sector = localStorage.getItem('sector');
        const route = 'status/setor/' + sector;
        http.get(route, {
            headers: {'x-auth': ` Bearer ${localStorage.getItem('jwt')} `}
        })
            .then(response => commit(GET_STATUS_BY_SECTOR, response.data.message))
            .catch(errors => {
                console.log(errors);
                notifyError('Algo deu errado. Contate o administrador!')
                Forbidden(errors);
            });
    },

    [REGISTER_STATUS]({commit}, formData) {
        http.post('status/cadastrar', formData, {
            headers: {'x-auth': ` Bearer ${localStorage.getItem('jwt')} `}
        })
            .then(response => {
                showNotify(response)
                if (!localStorage.getItem('Status')) setSessionStatus();
                let obj = JSON.parse(localStorage.getItem('Status'));
                const url = getUrl(obj.paramns);
                const route = 'status/listar/' + url;
                http.get(route, {
                    headers: {'x-auth': ` Bearer ${localStorage.getItem('jwt')} `}
                })
                    .then(response => {
                        commit(LIST_STATUS, response.data)
                        const button = document.getElementById('closeModalRegister');
                        button.click();
                        endLoading('form', 'save')
                    })
                    .catch(errors => {
                        console.log(errors); alert(1)
                        endLoading('form', 'save');
                        notifyError(errors.response.data.message)
                        Forbidden(errors);
                        return false;
                    });
            })
            .catch(errors => {
                console.log(errors);
                endLoading('form', 'save');
                notifyError(errors.response.data.message)
                Forbidden(errors);
                return false;
            })
    },
};

