export const KEY_JWT = 'lfeudmnskqoepoalrfowuqnadgpfdssfdasmasjubfslava';

export const ACCESS_BY_LEVEL = {
    1: ['dashboard', 'myProfile', 'users', 'status', 'trash', 'leads', 'audit'],
    2: ['dashboard', 'myProfile', 'leads'],
    3: ['dashboard', 'myProfile', 'leads'],
    4: ['dashboard', 'myProfile', 'leads'],
    5: ['dashboard', 'myProfile', 'leads', 'audit'],
    6: ['dashboard', 'myProfile', 'leads'],
    7: ['dashboard', 'myProfile', 'leads'],
}