// Mutations Usuários
export const LIST_USERS = 'LIST_USERS';
export const LIST_ADMINS = 'LIST_ADMINS';
export const LIST_CONSULTANTS = 'LIST_CONSULTANTS';

// Mutations Quadros
export const LIST_BOARDS = 'LIST_BOARDS';

// Mutations Lixeira
export const LIST_TRASH = 'LIST_TRASH';

// Mutations Setor
export const GET_SECTOR = 'GET_SECTOR';

// Mutations Status
export const GET_STATUS = 'GET_STATUS';
export const GET_STATUS_BY_SECTOR = 'GET_STATUS_BY_SECTOR';

// Mutations Leads
export const LIST_LEADS = 'LIST_LEADS';
export const LIST_LOGS = 'LIST_LOGS';
export const LIST_ACTIVITIES = 'LIST_ACTIVITIES';
export const ADD_ACTIVITY = 'ADD_ACTIVITY';